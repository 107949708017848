<template>
	<section>
		<a id="hospital"></a>
		<div class="container py-4 px-2 px-md-5">
			<div class="row">
				<div class="col-12 text-center">
					<h1 class="gold text-bold" v-html="$t('FOR CORPORATE')"></h1>
				</div>
			</div>

			<div class="row my-5 py-4">
				<u-animate-container class="col-md-6 px-3 text-center">
					<u-animate class="img-bd type2" name="fadeInUp">
						<img src="@/assets/images/corporate3.jpg" class="ir" />
					</u-animate>
				</u-animate-container>
				<div class="col-md-6  px-3 px-md-5">
					<h3 class="blue uppercase line-left"><b v-html="$t('Hospital Clinic Solution')"></b></h3>
					<p v-html="$t('Creating new digital health business and scale to a new customer segment with a non-boundary business ecosystem')"></p>
				</div>

			</div>
			<a id="smart"></a>
			<div class="row my-5 py-4">
				<div class="col-md-6 px-3 px-md-5 order-2 order-md-1">
					<h3 class="blue uppercase line-right"><b v-html="$t('Smart City - Smart Health Solution')"></b></h3>
					<p v-html="$t('smart_city_detail')"></p>
				</div>
				<u-animate-container class="col-md-6 px-3 text-center order-1 order-md-2">
					<u-animate class="img-bd type3" name="fadeInUp">
						<img src="@/assets/images/corporate1.jpg" class="ir" />
					</u-animate>
				</u-animate-container>
			</div>

			<a id="insurance"></a>
			<div class="row my-5 py-4">
				<u-animate-container class="col-md-6 px-3 text-center">
					<u-animate class="img-bd type2" name="fadeInUp">
						<img src="@/assets/images/corporate2.jpg" class="ir" />
					</u-animate>
				</u-animate-container>
				<div class="col-md-6  px-3 px-md-5">
					<h3 class="blue uppercase line-left"><b v-html="$t('Insurance Solution')"></b></h3>
					<p v-html="$t('We help insurance connect with hospitals more efficiently process with customer’s digital verification. Creating a modern insurance business')"></p>
				</div>
			</div>

			<div class="row my-5 py-4">
				<div class="col-md-6 px-3 px-md-5 order-2 order-md-1">
					<h3 class="blue uppercase line-right"><b v-html="$t('Employees Health Benefit Solution')"></b></h3>
					<p v-html="$t('Modern employee’s privilege & healthcare bring more company’s engagement and employee’s well-being')"></p>
				</div>
				<u-animate-container class="col-md-6 px-3 text-center order-1 order-md-2">
					<u-animate class="img-bd type3" name="fadeInUp">
						<img src="@/assets/images/corporate4.jpg" class="ir" />
					</u-animate>
				</u-animate-container>
				<a id="employee"></a>
			</div>
			<div class="row my-5 py-4">
				<u-animate-container class="col-md-6 px-3 text-center">
					<u-animate class="img-bd type2" name="fadeInUp">
						<img src="@/assets/images/corporate3.jpg" class="ir" />
					</u-animate>
				</u-animate-container>
				<div class="col-md-6  px-3 px-md-5">
					<h3 class="blue uppercase line-left"><b v-html="$t('Medical Tourism & Hospitality Solution')"></b></h3>
					<p v-html="$t('Quickly creating a new digital medical tourism business and scale to worldwide services')"></p>
				</div>
				<a id="medical"></a>
			</div>
		</div>

		<div class="container-fluid bg-form2">
			<div class="container py-5">
				<div class="row">
					<div class="col-12 text-center">
						<h1 class="text-bold" v-html="$t('INTERNATIONAL BUSINESS EXPANSION')"></h1>
					</div>
					<div class="col-md-10 offset-md-1 text-center">
						<p v-html="$t('INTERNATIONAL BUSINESS EXPANSION DETAIL')"></p>
					</div>
				</div>
			</div>
		</div>

		<a id="qna"></a>
		<div class="container-fluid bg-gray">
			<div class="container-md py-5 px-3 px-md-5">
				<div class="row">
					<div class="col-12 text-center">
						<h2><b v-html="$t('Browse Questions')"></b></h2>
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<div class="carousel qna">
							<div class="title" :class="{'active': this.qna1}" @click.prevent="toggleActive('qna1')" v-html="$t('q1')"></div>
							<div class="content" :class="{'active': this.qna1}">
								<div class="content-item" v-html="$t('a1')"></div>
							</div>
						</div>
						<div class="carousel qna">
							<div class="title" :class="{'active': this.qna2}" @click.prevent="toggleActive('qna2')" v-html="$t('q2')"></div>
							<div class="content" :class="{'active': this.qna2}">
								<div class="content-item" v-html="$t('a2')"></div>
							</div>
						</div>
						<div class="carousel qna">
							<div class="title" :class="{'active': this.qna3}" @click.prevent="toggleActive('qna3')" v-html="$t('q3')"></div>
							<div class="content" :class="{'active': this.qna3}">
								<div class="content-item" v-html="$t('a3')"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="container-fluid bg-form3">
			<div class="container py-0 pt-md-5">
				<div class="row">
					<div class="col-lg-6 py-5">
						<form class="apply" @submit.prevent="sendRequestDemo">
							<div class="row">
								<div class="col-12">
									<h1 class="text-bold" v-html="$t('REQUEST A DEMO')"></h1>
								</div>
								<div class="col-12">
									<p v-html="$t('Complete the form below, and we will contact you to schedule a demo within 1-2 business days')"></p>
								</div>
								<div class="col-lg-6">
									<label v-html="$t('Company Name')"></label>
									<input type="text" required v-model="model.companyName" />
								</div>
								<div class="col-lg-6">
									<label v-html="$t('Your Name')"></label>
									<input type="text" required v-model="model.contactName" />
								</div>
								<div class="col-12">
									<label v-html="$t('Industry Type')"></label>
									<select required v-model="model.industryType">
										<option value="" selected disabled v-html="$t('Select')"></option>
										<option value="โรงพยาบาล และคลีนิค" v-html="$t('HOSPITAL & CLINIC SOLUTION')"></option>
										<option value="ธุรกิจภาครัฐ" v-html="$t('SMART CITY SOLUTION')"></option>
										<option value="ธุรกิจประกัน" v-html="$t('INSURANCE SOLUTION')"></option>
										<option value="ธุรกิจบริษัทเอกชน" v-html="$t('EMPLOYEE\'S HEALTH BENEFIT SOLUTION')"></option>
										<option value="ธุรกิจส่งเสริมการท่องเที่ยว" v-html="$t('MEDICAL TOURISM & HOSPITALITY SOLUTION')"></option>
									</select>
								</div>
								<div class="col-lg-6">
									<label v-html="$t('Phone')"></label>
									<input type="tel" maxlength="10" required v-model="model.phone" />
								</div>
								<div class="col-lg-6">
									<label v-html="$t('E-mail')"></label>
									<input type="email" required v-model="model.email" />
								</div>
								<div class="col-12">
									<label v-html="$t('Message')"></label>
									<textarea class="none-resize" required v-model="model.message"></textarea>
								</div>
								<div class="col-12 text-center text-md-left">
									<input type="submit" class="btn-submit" :value="$t('CONFIRM')" />
								</div>
							</div>
							<a id="form"></a>
						</form>
					</div>
					<div class="col-lg-6 pb-5 d-flex align-items-center align-items-md-start text-center">
						<div class="px-5 py-5"><img src="@/assets/images/img-form1.png" class="ir" /></div>
					</div>
				</div>
			</div>

			<a id="testimonial"></a>
			<div class="container py-0 pb-md-5">
				<div class="row">
					<div class="col-12 py-5 text-center">
						<h2><b v-html="$t('Testimonials')"></b></h2>
					</div>
					<div class="col-12 pb-5 text-center">
						<VueSlickCarousel :autoplay="true" :speed="1500" :pauseOnHover="false" class="row testimonial-slide">
							<div class="col-12">
								<div class="row">
									<div class="col-md-3 text-center px-4">
										<div class="image-container"><i class="quote"></i>
											<div class="image"><img src="@/assets/images/media/testimonial1.jpg" class="ir" /></div>
										</div>
									</div>
									<div class="col-md-9 px-5">
										<div class="content" v-html="$t('Doctor A to Z is engaged in not just offering technology, but helping us solve problems - and that’s what we need')"></div>
										<div class="by"><b>Doctor A to Z | </b><i class="star"></i><i class="star"></i><i class="star"></i><i class="star"></i><i class="star"></i></div>
									</div>
								</div>
							</div>
						</VueSlickCarousel>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

export default {
	components: {
		VueSlickCarousel
	},
	data: () => ({
		qna1: false,
		qna2: false,
		qna3: false,
		model: {
			companyName: '',
			contactName: '',
			industryType: '',
			phone: '',
			email: '',
			message: ''
		},
		modelEmail: {
			type: 'admin',
			subject: 'Request demo from website Doctor A to Z',
			from: '',
			to: process.env.VUE_APP_EMAIL_SERVICE,
			html: ''
		}
	}),
	methods: {
		toggleActive(name) {
			this[name] = !this[name]
		},
		async sendRequestDemo() {
			let html = ''
			html += '<div>Company Name: ' + this.model.companyName + '</div><br/>';
			html += '<div>Contact Name: ' + this.model.contactName + ' </div><br/>';
			html += '<div>Industry Type: ' + this.model.industryType + ' </div><br/>';
			html += '<div>Email: ' + this.model.email + ' </div><br/>';
			html += '<div>Phone: ' + this.model.phone + ' </div><br/>';
			html += '<div>Message ' + this.model.message + ' </div><br/><br/>';
			html += '<div>Sincerly yours,</div><br/>';
			html += '<div>Doctor A to Z</div>';

			this.modelEmail.html = html;
			this.modelEmail.from = this.model.email;

			let urlEmail = "/email";
			await window.axios.post(urlEmail, this.modelEmail).then(async () => {
				alert(this.$t('Contact information has been sent successfully'));
				this.model = {
					companyName: '',
					contactName: '',
					industryType: '',
					phone: '',
					email: '',
					message: ''
				}
			}).catch(error => {
				console.log(urlEmail, error)
			});
		}
	},
}
</script>